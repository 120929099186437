import { useEffect } from "react";
import Aos from "aos";
import ImageHeader from "./assets/images/img-header.png";
import ImageLogo from "./assets/images/img-logo.png";
import IconX from "./assets/icons/icon-x.png";
import IconTele from "./assets/icons/icon-tele.png";
import ImageIntro from "./assets/images/img-intro.png";
import ImageTool1 from "./assets/images/img-tool-1.png";
import ImageTool2 from "./assets/images/img-tool-2.png";
import ImageTool3 from "./assets/images/img-tool-3.png";
import ImageTool4 from "./assets/images/img-tool-4.png";
import ImageAbout from "./assets/images/img-about.png";
import ImageTitle from "./assets/images/img-title.png";
import ImageTitle2 from "./assets/images/img-title-2.png";
import ImageTitle3 from "./assets/images/img-title-3.png";
import ImageTitle4 from "./assets/images/img-title-4.png";
import ImageBox from "./assets/images/img-box.png";
import ImageBox2 from "./assets/images/img-box-2.png";
import ImageCommunity from "./assets/images/img-footer.png";
import "aos/dist/aos.css";
import "./App.scss";

function App() {
  const handleClick = (value) => {
    const element = document.getElementById(value);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);
  return (
    <div className="App">
      <main className="page-main">
        <section className="section-intro">
          <div data-aos="fade-up" className="container wrapper">
            <div className="header-menu">
              <img className="img-header" src={ImageHeader} alt="" />
              <div className="header-wrapper">
                <div className="img-logo">
                  <img src={ImageLogo} alt="" />
                </div>
                <nav className="menu">
                  <ul>
                    <li onClick={() => handleClick("about")}>About</li>
                    <li onClick={() => handleClick("token")}>tokenomics</li>
                    <li onClick={() => handleClick("roadmap")}>roadmap</li>
                    <li onClick={() => handleClick("contact")}>contact</li>
                  </ul>
                </nav>
                <div className="socials">
                  <a
                    href="https://t.me/PenguinWifHat"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={IconTele} alt="" />
                  </a>
                  <a
                    href="https://x.com/PeguinWifHatSOL"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={IconX} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="content">
              <div className="left">
                <img src={ImageIntro} alt="" />
              </div>
              <div className="right">
                <p className="title">welcome to</p>
                <div className="content-logo">
                  <img src={ImageLogo} alt="" />
                </div>
                <p className="value">CA: TBA</p>
                <div className="list-tools">
                  <div className="top">
                    <img className="img-1" src={ImageTool1} alt="" />
                    <img className="img-2" src={ImageTool2} alt="" />
                  </div>
                  <div className="bottom">
                    <img className="img-3" src={ImageTool3} alt="" />
                    <img className="img-4" src={ImageTool4} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="about" className="section-about">
          <div data-aos="fade-up" className="container wrapper">
            <div className="left">
              <img src={ImageAbout} alt="" />
            </div>
            <div className="right">
              <div className="img-title">
                <img src={ImageTitle} alt="" />
              </div>
              <p className="des">
                Penguinwifhat Coin is a new meme cryptocurrency, featuring an
                image of a penguin wearing a woolen hat and hugging a fish,
                delivering a message about global warming. The melting ice
                threatens the lives of penguins, and Penguinwifhat Coin calls
                for everyone to take action to protect the environment. Join the
                Penguinwifhat Coin community to share this message, fundraise,
                and raise awareness about climate change. Penguinwifhat Coin –
                Joy and Responsibility for the Planet. Join hands with
                Penguinwifhat Coin to protect penguins and their icy homes,
                while enjoying the fun and creativity of a meaningful and
                innovative meme cryptocurrency!
              </p>
            </div>
          </div>
        </section>
        <section id="token" className="section-token">
          <div data-aos="fade-up" className="container wrapper">
            <div className="img-title">
              <img src={ImageTitle2} alt="" />
            </div>
            <ul className="list-token">
              <li>
                <img src={ImageBox} alt="" />
                <div className="content">
                  <p className="title">Total supply</p>
                  <p className="value">100M</p>
                </div>
              </li>
              <li>
                <img src={ImageBox} alt="" />
                <div className="content">
                  <p className="title">Buy/Sell Tax</p>
                  <p className="value">0%</p>
                </div>
              </li>
              <li>
                <img src={ImageBox} alt="" />
                <div className="content">
                  <p className="title">Liquidity</p>
                  <p className="value">BURN</p>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <section id="roadmap" className="section-roadmap">
          <div data-aos="fade-up" className="container wrapper">
            <div className="img-title">
              <img src={ImageTitle3} alt="" />
            </div>
            <ul className="list-stage">
              <li>
                <img src={ImageBox2} alt="" />
                <div className="content">
                  <p className="title">stage 1</p>
                  <p className="item">Community Creation</p>
                  <p className="item">Contract Deployment</p>
                  <p className="item">Website Development</p>
                  <p className="item">Pre-launch Marketing</p>
                </div>
              </li>
              <li>
                <img src={ImageBox2} alt="" />
                <div className="content">
                  <p className="title">stage 2</p>
                  <p className="item">Token Launch</p>
                  <p className="item">More Marketing</p>
                  <p className="item">Solana Trending</p>
                  <p className="item">CEX Listing</p>
                </div>
              </li>
              <li>
                <img src={ImageBox2} alt="" />
                <div className="content">
                  <p className="title">stage 3</p>
                  <p className="item">KOL Partnership</p>
                  <p className="item">NFT Release </p>
                  <p className="item">Marketplace Launch</p>
                  <p className="item">Airdrop Campaign</p>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <section className="section-h2b">
          <div data-aos="fade-up" className="container wrapper">
            <div className="img-title">
              <img src={ImageTitle4} alt="" />
            </div>
            <ul className="list-step">
              <li>
                <span className="content-1">STEP 1</span>
                <span className="content-2">Connect Your Wallet</span>
                <span className="content-3">
                  Visit raydium or birdeye, and connect your wallet (e.g.,
                  phantom, MetaMask, Trust Wallet).
                </span>
              </li>
              <li>
                <span className="content-1">STEP 2</span>
                <span className="content-2">Select PENGUINWIFHAT Token</span>
                <span className="content-3">
                  Choose sol as your payment and search for
                  <span> Penguinwifhat</span>
                  in the "Swap to" field. If not listed, enter the
                  <span> Penguinwifhat</span> contract address.
                </span>
              </li>
              <li>
                <span className="content-1">STEP 3</span>
                <span className="content-2">Swap</span>
                <span className="content-3">
                  Input how much SOL you want to swap, check the estimated
                  <span> Penguinwifhat</span> you'll receive, adjust slippage if
                  needed, and confirm the swap in your wallet. Make sure your
                  wallet is funded with enough ETH for the purchase and
                  transaction fees.
                </span>
              </li>
            </ul>
          </div>
        </section>
        <section id="contact" className="section-community">
          <div data-aos="fade-up" className="container wrapper">
            <div className="img-community">
              <img src={ImageCommunity} alt="" />
            </div>
            <h3>
              JOIN THE <span>COMMUNITY</span>
            </h3>
            <p>Join us and become PenguinWifHat community</p>
            <div className="socials">
              <a
                href="https://t.me/PenguinWifHat"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IconTele} alt="" />
              </a>
              <a
                href="https://x.com/PeguinWifHatSOL"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={IconX} alt="" />
              </a>
            </div>
          </div>
        </section>
      </main>
      <footer className="page-footer">
        <div className="container wrapper">
          <p>
            Copyright © 2024 - All Rights Reserved By <span>penguinWIFHAT</span>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default App;
